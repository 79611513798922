<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-close route-name="scale_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div class="card">
        <div class="card-header">{{ scale.siteName }} - {{ scale.scaleGroup }} - {{ scale.name }}</div>
        <div class="card-body">
          <section class="content form-horizontal">
            <div class="box box-info">
              <div class="box-body">
                <div class="row">
                  <div class="col-lg-4">
                    <input type="hidden" v-model="scale.id">
                    <app-input
                      v-model="scale.value"
                      type="number"
                      id="scale_value"
                      :label="$t('scale.value')"
                      :step="0.1"
                      :min="0"
                      :max="10"
                    >
                    </app-input>
                  </div>
                  <div class="col-lg-4">
                    <app-input
                      v-model="scale.groupValue"
                      type="number"
                      id="scale_groupValue"
                      :label="$t('scale.groupValue')"
                      :step="0.01"
                      :min="0"
                      :max="1"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import Input from '../form/inputs/Input'
import ScaleModel from '../../model/Scale'
import ButtonClose from '../shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'ScaleEdit',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      scale: this._.cloneDeep(ScaleModel),
      scaleManagers: []
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonClose: ButtonClose
  },
  computed: {
    isSaveButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SCALE_PERMISSIONS.saveButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    sites () {
      return this.$store.getters['site/all']
    }
  },
  methods: {
    getScale () {
      this.$store.dispatch('scale/fetchOne', this.$route.params.id)
        .then(() => {
          this.scale = this.$store.getters['scale/detail']
        })
    },
    async save () {
      this.$store.dispatch('scale/update', this.scale)
        .then(() => {
          if (this.$store.getters['scale/error'] === null) {
            this.scale = this.$store.getters['scale/detail']
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['scale/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getScale()
  }
}
</script>
